const initialState = {
    userInfo: {
        displayName: "",
        userAvatar: "",
        userEmail: "",
        phoneNumber: 0,
        refCode: "",
        userCreatedAt: "",
        isCheckIn: false,
        diamond: 0,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER_INFO":
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

export { initialState };
export default reducer;
