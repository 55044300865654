import React, { forwardRef, useImperativeHandle, useState } from "react";
import Confetti from "react-confetti";

const CelebrationComponent = forwardRef((props, ref) => {
    const [showConfetti, setShowConfetti] = useState(false);

    const width = window.innerWidth;
    const height = window.innerHeight;

    useImperativeHandle(ref, () => ({
        start() {
            setShowConfetti(true);
            setTimeout(() => {
                setShowConfetti(false);
            }, 5000);
        },
        stop() {
            setShowConfetti(false);
        },
    }));

    return (
        <div style={{ textAlign: "center" }} className="bg-green">
            {showConfetti && (
                <Confetti
                    width={width}
                    height={height}
                    recycle={false}
                    numberOfPieces={500}
                    className="fixed z-[9999] top-0 inset-1 bg-black/80"
                />
            )}
        </div>
    );
});

export default CelebrationComponent;
