import React, { Suspense } from "react";
import Loading from "./based/Loading";

const HomePage = React.lazy(() => import("./entities/homepage/homepage"));
const GamePage = React.lazy(() => import("./entities/gamepage/gamepage"));
const AppRoutes = [
    {
        path: "/",
        element: (
            <Suspense fallback={<Loading isOpen={true} />}>
                <HomePage />
            </Suspense>
        ),
    },
    {
        path: "/game/",
        element: (
            <Suspense fallback={<Loading isOpen={true} />}>
                <GamePage />
            </Suspense>
        ),
    },
];

export default AppRoutes;
