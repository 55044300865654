import React, { createContext, useContext, useRef } from "react";
import CelebrationComponent from "./Celebration";

const CelebrationContext = createContext();

export const CelebrationProvider = ({ children }) => {
    const celebrationRef = useRef();

    return (
        <CelebrationContext.Provider value={celebrationRef}>
            {children}
            <CelebrationComponent ref={celebrationRef} />
        </CelebrationContext.Provider>
    );
};

export const useCelebration = () => {
    const celebrationRef = useContext(CelebrationContext);
    return celebrationRef.current;
};
