import axios from "axios";
import { QueryClient } from "@tanstack/react-query";
import cookie from "react-cookies";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const BASE_MEDIA_URL = process.env.REACT_APP_AJAX_UPLOAD_PERMALINK;

const BaseRequest = axios.create({
    baseURL: BASE_URL,
});

// Axios Middleware
BaseRequest.interceptors.request.use(
    async (config) => {
        let token = cookie.load("access_token");
        if (token) {
            config.headers["X-Authorization"] = token;
        }
        if (typeof window !== "undefined" && isURLAllowed(config.url)) {
            await window.getPassport((passportData) => {
                const { __passport, __passport_verified, __passport_with_key } =
                    passportData;
                config.headers["X-Passport"] = __passport;
                config.headers["X-Passport-Verified"] = __passport_verified;
                config.headers["X-Passport-With-Key"] = __passport_with_key;
            });
        }
        const cacheBuster = Math.floor(Math.random() * 100000000).toString();
        if (config.url?.includes("?")) {
            config.url += `&cacheBuster=${cacheBuster}`;
        } else {
            config.url += `?cacheBuster=${cacheBuster}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

function isURLAllowed(url) {
    const router_must_sign = [
        "login",
        "register",
        "recover_password",
        "user/set_new_password",
        "user/active_account",
        "quick-login",
        "contactform",
        "user/onetimepassword",
        "webauthn/generate-registration-options",
        "webauthn/generate-authentication-options",
    ];

    if (!url) return false;

    return router_must_sign.some((route) => url.includes(route));
}
export const queryClient = new QueryClient();
export default BaseRequest;
