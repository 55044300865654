import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { CelebrationProvider } from "./based/Celebration/CelebrationConfig";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./queries/BaseRequest";
import { GlobalStateProvider } from "./store/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Router>
            <CelebrationProvider>
                <QueryClientProvider client={queryClient}>
                    <GlobalStateProvider>
                        <App />
                    </GlobalStateProvider>
                </QueryClientProvider>
            </CelebrationProvider>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
