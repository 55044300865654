// GlobalStateContext.js
import React, { createContext, useReducer, useContext } from "react";
import reducer, { initialState } from "./Reducer";

const GlobalState = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <GlobalState.Provider value={{ state, dispatch }}>
            {children}
        </GlobalState.Provider>
    );
};

export const useGlobalState = () => useContext(GlobalState);
